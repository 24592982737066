/**=====================
     47. Footer CSS Start
==========================**/
.footer-links{
  text-align: $footer_link_align;
  li{
    display: inline-block;
    padding-right: 15px;
    a{
      font-size: 12px;
    }
  }
}
footer{
  background-color: $footer_bg_color;
  border-top: 1px solid $footer_top_bgr_color;
  padding: 15px;
  bottom: 0;
  left: 0;
  a{
    font-weight: 600;
  }
  &.footer-starter-kit{
    bottom: -52px;
  }
  &.blockquote-footer{
    bottom: unset;
    margin-left: 0 !important;
  }
}
  .page-wrapper{
    .page-body-wrapper{
      &.hasfooterfix{
          margin-bottom: 50px;
      }
      .footer-dark{
        background-color: $footer_dark_color;
        p{
          color: #a5a5a5;
        }
        li{
          a{
            color: $footer_dark__txt_color;
          }
        }
    }
  }
  }

.footer-fix{
  position: fixed;
  width: calc(100% - 255px);
}
.page-body{
  .footer-fix ~ &{
    background-color: red;
  }
}

.page-wrapper{
  .page-body-wrapper{
    footer{      
      margin-left: 255px;
      transition: all 0.3s ease;
      bottom: 0;
      z-index: 8;
      p{
        color: $theme-body-font-color;
        i{
          color: $secondary-color;
          margin-left: 5px;
        }
      }
    }
  }

}
/**=====================
     47. Footer CSS Ends
==========================**/

