$header-size: 130px;

/**=====================
    05. Header CSS Start
==========================**/
@keyframes pagebody{
  from {
   opacity: 0;
   transform: scale3d(0.95, 0.95, 0.95) translate(-20px, -100px);
 }
 50% {
   opacity: 1;
 }
}
@keyframes zoomOut {
 0% {
   opacity: 1;
 }
 50% {
   opacity: 0;
   transform: scale3d(.3, .3, .3);
 }
 to {
   opacity: 0;
 }
}
@keyframes slideInLeft {
 0% {
   transform: translate3d(-100%, 0, 0);
   visibility: visible;
 }
 to {
   transform: translateZ(0);
 }
}
@keyframes fadeInDown {
 0% {
   opacity: 0;
   transform: translate3d(0, -100%, 0);
 }
 to {
   opacity: 1;
   transform: none;
 }
}
@keyframes fadeIn {
 0% {
   opacity: 0;
 }
 to {
   opacity: 1;
 }
}
%common {
  border-left-color: $dark-color;
  transition: $sidebar-transition;
}
%for-animated-hover {
  box-shadow: 0 0 2px 2px $light-color;
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  left: 0;
}
%for-animated-hover-box{
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
  &:before{
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $white;
    content: "";
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 2;
  }
  &:after{
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $dark-gray;
    content: "";
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 1;
  }
}
%sidebar-icon {
  display: block;
  transform: rotateX(0deg);
  z-index: 1;
  background-color: $white;
  color: $dark-gray;
  font-size: 14px;
  opacity: 1;
  margin-top: -70px;
  padding: 10px 0;
  text-align: left;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
  position: absolute;
  width: 245px;
  margin-left: 115px;
}
/*======= Page Header css Start ======= */
.compactLogo {
  a {
    img {
      &:first-child {
        display: none;
      }
    }
  }
  &.show {
    a {
      img {
        &:first-child {
          display: block;
          margin: 0 auto;
        }
        &:last-child {
          display: none;
        }
      }
    }
  }
}
.page-wrapper{
  .page-main-header{
    background-color: $main-header-bg-color; 
    height: $header-size;
    position: $main-header-position;
    top: $main-header-top;
    z-index: $main-header-z-index;
    width: calc(100% - #{$sidebar-width});
    display: flex;
    align-items: center;
    margin-left: $sidebar-width;
    transition: $sidebar-transition;
    &.open{
      margin-left: 0;
      width: 100%;
      transition: $sidebar-transition;
    }
    .show {
        > .btn-secondary {
          &.dropdown-toggle {
            color: $theme-body-font-color;
          }
        }
    }
    .btn-secondary {
      background-color: $transparent-color !important;
      border-color: $transparent-color !important;
      color: $theme-body-font-color;
      &:active, &.active {
        color: $theme-body-font-color;
      }
    }
  }
  .page-body-wrapper{
    .page-body{
      min-height: calc(100vh - #{$header-size});
      margin-top: $header-size;
      padding: $page-body-padding;
      position: relative;
      background-color: $light;
      > div {
        > div {
          &.zoomout-enter-done {
            animation: zoomOut 1000ms ease-in-out;
          }
          &.slidefade-enter-done {
            animation: slideInLeft 1000ms ease-in-out;
          }
          &.fade-enter-done {
            animation: fadeIn 1000ms ease-in-out;
          }
          &.fadebottom-enter-done {
            animation: fadeInDown 1000ms ease-in-out;
          }
          &.slidefade-enter, &.slidefade-exit, &.fade-enter, &.fade-exit, &.zoomout-enter, 
          &.zoomout-exit, &.fadebottom-enter, &.fadebottom-exit, &.none-enter-done, &.none-enter, &.none-exit {
            animation: unset;
          }
        }
      }
    }
    .page-header{
      padding-top: $page-header-top-padding;
      padding-bottom: $page-header-padding;
      .row{
        align-items: center;
        h3{
          font-size: $page-title-font-size;
          margin-bottom: $page-title-margin-bottom;
          font-weight: $page-title-font-weight;
          text-transform: $page-title-text-tranform;
          small{
            display: $page-small-title-display;
            font-size: $page-small-title-font-size;
            margin-bottom: $page-small-title-margin-bottom;
            text-transform: $page-small-title-text-transform;
            color: $theme-body-sub-title-color;
          }
        }
      }
      .breadcrumb{
        background-color: $transparent-color;
        padding: 0;
        margin-top: 5px;
        margin-bottom: 0;
        li{
          color: $black;
          font-family: $font-nunito;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 1px;
        }
        .breadcrumb-item{
          &+.breadcrumb-item{
            &:before{
              content: $breadcrumb-content;
            }
          }
          a{
            color: $theme-body-font-color ;
            font-family: $font-nunito;
            svg{
              width: $breadcrumb-size;
              height: $breadcrumb-size;
            }
          }
        }
      }
    }
  }
}
/*======= Page Header css ends  ======= */


/**======Main Header css Start ======**/
.page-main-header{
  max-width: 100vw;
  .main-header-right{
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 25px 40px;
    margin: 50px 30px;
    /*
    border-radius: 20px;
    -webkit-box-shadow: 0 0 15px 10px #f7f7f7;
            box-shadow: 0 0 15px 10px #f7f7f7;
            */
    position: relative;
    
    /* GLASS MORPHISM WHTE  */
    background: rgba( 255, 255, 255, 0.10 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 10.5px );
    -webkit-backdrop-filter: blur( 10.5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
   
    .vertical-mobile-sidebar {
      display: none;
    }
    .mobile-sidebar {
      padding-right: 20px;
      .switch-sm {
        .switch {
          input{
            &:checked  {
              + .switch-state {
                border: 2px solid $theme-font-color;
                background-color: $white;
              }
            }
          }
          .switch-state {
            border: 2px solid $theme-font-color;
            &:before {
              border: 2px solid $theme-font-color;
              bottom: 2px;
            }
          }
        }
        input {
          &:checked {
            + .switch-state{
              &:before {
                left: -16px;
                border: 2px solid $theme-font-color;
                bottom: 2px;
              }
            }
          }
        }
      }
    }
    .search-full {
      opacity: 0;
      z-index: -1;
      position: absolute;
      .form-group {
        padding: 0;
        position: relative;
        &:before {
          position: absolute;
          left: 30px;
          top: 22px;
          content: "\f002";
          color: $dark-gray;
          font-family: $font-awesome;
        }
        .close-search {
          position: absolute;
          right: 30px;
          top: 25px;
          cursor: pointer;
          color: $dark-gray;
        }
      }
      input {
        width: 100%;
        line-height: 65px;
        border-radius: 20px;
        border-color: $transparent-color;
        padding-left: 60px;
      }
      &.open {
        z-index: 999;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        opacity: 1;
      }
    }
    .nav-left{
      i{
        margin-right: 20px;
      }
      input:focus{
        outline: 0 !important;
      }
    }
    .nav-right{
      text-align: left;
      padding-left: $main-header-right-nav-right;
      padding-right: $main-header-right-nav-right;
      .dropdown {
        .btn {
          padding: 0;
        }
        .dropdown-toggle {
          &::after {
            vertical-align: middle;
            border-top: 0.3em solid $gray-60;
          }
        }
        .dropdown-menu {
          margin: 20px 0 0;
          box-shadow: none;
          .dropdown-item {
            font-size: 14px;
          }
          ul {
            li {
              display: block;
              font-size: 14px;
            }
          }
        }
      }
      ul{
        li{
          a {
            color: $theme-font-color;
          }
          svg{
            margin-top: $main-header-right-nav-icon-margin-top;
            width: $main-header-right-nav-icon-size;
            height: $main-header-right-nav-icon-size;
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
      > ul {
        padding: 0 0;
        .search-form {
          .mobile-search {
            svg{
              color: $primary-color;
            }
          }
          .form-group {
            margin-bottom: 0;
            &:before, &:after{
              display: none;
            }
          }
        }
        > li:first-child {
          width: auto;
          margin: 0;
        }
      }
      &.right-menu {
        ul {
          justify-content: flex-end;
          li {
            position: relative;
            &:before {
              display: none;
            }
            &:first-child {
              input {
                display: inherit !important;
              }
            }
            .search-icon {
              border-radius: 100%;
              background-image: $gradient-primary;
              width: 36px;
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
              box-shadow: $common-box-shadow;
              cursor: pointer;
              position: relative;
              i {
                color: $white;
              }
            }
            .search-form {
              input {
                padding: 10px 10px 10px 30px;
              }
            }
            &:nth-child(2) {
              border-left: 1px solid $light-semi-gray;
              border-right: 1px solid $light-semi-gray;
            }
            .user-header {
              img {
                width: 35px !important;
                height: 35px !important;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
              }
              .media-body {
                text-align: left;
                span {
                  + span {
                    font-size: 9px;
                    font-weight: 600;
                  }
                }
              }
            }
            .notification {
              position: absolute;
              top: -2px;
              right: -9px;
              padding: 5px 7px;
            }
          }
        }
        .dropdown {
          .dropdown-toggle {
            &::after {
              display: none;
            }
          }
        }
      }
      .icon-user{
        font-size: 16px;
      }
      >ul{
        display: flex;
        align-items: center;
        > li{
          position: relative;
          padding: 0 20px;
          .badge {
            padding: 4px 8px;
          }
          &:before {
            position: absolute;
            content: '';
            top: -6px;
            left: 0;
            background-color: $light-semi-gray;
            width: 1px;
            height: 32px;
          }
          &:first-child {
            &:before {
              top: 2px;
            }
          }
          span{
            letter-spacing: 0.9px;
            .fa {
              color: $gray-60;
            }
            ul{
              left: inherit;
              right: -10px;
              width: 130px;
              &:before,&:after{
                left: inherit;
                right: 10px;
              }
              li{
                display: block;
                a{
                  font-size: 14px;
                  color: $dark-color;
                  i{
                    margin-left: 10px;
                    font-size: 13px;
                    color: $dark-color;
                  }
                }
              }
            }
          }
        }
        .flag-icon{
          font-size: 16px;
        }
      }
      .notification-dropdown{
        width: 300px;
        li {
          padding: 15px !important;
          &:first-child {
            text-align: center;
            color: $white;
            padding: 20px !important;
          }
          &:nth-child(3) {
            padding-top: 0 !important;
          }
          .notification-icons {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              path {
                color: $white;
              }
            }
          }
          h6 {
            margin-bottom: 2px;
          }
        }
      }
      .category-dropdown{
        text-align: left;
        li{
          a{
            color: $dark-color;
            i{
              margin-right: 10px;
            }
          }
        }
      }
      .status-dropdown {
        width: 255px;
        li {
          padding: 10px !important;
          &:first-child {
            background-color: $secondary-color;
          }
          &:nth-child(2) {
            padding-top: 20px !important;
          }
          &:last-child {
            padding-bottom: 25px !important;
          }
          h6 {
            color: $white;
          }
        }
      }
      .profile-dropdown{
        width: 180px;
        li{
          padding: 10px !important;
          + li {
            + li {
              padding-top: 0 !important;
            }
          }
          svg {
            vertical-align: text-bottom;
            margin-right: 10px;
            margin-top: 0;
          }
          &:first-child {
            padding: 20px !important;
            text-align: center;
            span {
              font-size: 10px;
            }
          }
          &:nth-child(2) {
            padding-top: 18px !important;
          }
          &:last-child {
            padding-bottom: 18px !important;
          }
        }
      }
      .droplet-dropdown {
        width: 380px;
        li {
          padding-top: 15px !important;
          &:first-child {
            padding: 20px !important;
          }
          &:last-child {
            padding-bottom: 15px;
          }
          .btn {
            padding: 0.375rem 1.75rem;
          }
          .row {
            margin-left: 0;
            margin-right: 0;
            .droplet-main {
              border-right: 1px solid $light-semi-gray;
              border-bottom: 1px solid $light-semi-gray;
              padding: 20px;
              text-align: center;
              svg {
                margin-top: 0;
                margin-bottom: 5px;
              }
              &:nth-child(3n) {
                border-right: none;
              }
            }
          }
        }
      }
    }
    li{
      display: inline-block;
      position: relative;

    }
  }
}
.onhover-dropdown{
  cursor: pointer;
  position: relative;
  &:hover{
    .onhover-show-div{
      @extend  %for-animated-hover-box;
    }
  }
}
.onhover-show-div {
  top: 80px;
  position: absolute;
  z-index: 7;
  background-color: $white;
  transition: all linear 0.3s;
  @extend  %for-animated-hover;
  li{
    a{
      svg{
        margin-top: 0 !important;
        path, line{
          color: $theme-body-font-color !important;
        }
      }
    }
  }
}
/**======Main Header css Ends ======**/
.scorlled{
  .page-sidebar{
    top:0 !important;
    height: 100vh !important;
  }
}

/* ========sidebar icon vertical menu start=======*/


.compact-switch{
  display: none
}
.sidebar-toggle-btn {
  font-size: 18px;
  margin-left: 10px;
  cursor: pointer;
}
/* ========sidebar icon vertical menu end=======*/


// Change header logo dynamically

.header-logo {
  margin-right: 20px;
  &.normal {
     .lightlogo {
       display: none;
     }
     .normallogo {
      display: block;
     }
  }
  &.light {
    .lightlogo {
      display: block;
    }
    .normallogo {
     display: none;
    }
 }
}

/**=====================
    05. Header CSS Ends
==========================**/
